import React, { createContext, useContext, useReducer } from "react";
import CoachesReducer from "../reducers/CoachesReducer";
import CoachesService from "../services/CoachesService";
import {
  COACHES_RECIBIDAS,
  CREATE_COACH,
  SET_COACH,
  SET_PROPIEDAD_COACH,
} from "../types";
import { hideModal } from "../utils";
import { ModalContext } from "./ModalContext";

const initialState = {
  coaches: null,
  coach: null,
};

export const CoachesContext = createContext(initialState);

export const CoachesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CoachesReducer, initialState);

  const { success } = useContext(ModalContext);

  const getCoaches = () => {
    CoachesService.getCoaches().then((res) => {
      const { instructors } = res.data;
      dispatch({ type: COACHES_RECIBIDAS, payload: instructors });
    });
  };

  const getCoach = (instructor_id) => {
    CoachesService.getSingleCoach(instructor_id).then((res) => {
      const { coach } = res.data;
      dispatch({ type: SET_COACH, payload: coach });
    });
  };

  const setCoach = (coach) => {
    dispatch({ type: SET_COACH, payload: coach });
  };

  const createCoach = () => {
    dispatch({ type: CREATE_COACH });
  };

  const setPropiedadCoach = (key, value) => {
    dispatch({ type: SET_PROPIEDAD_COACH, payload: { key, value } });
  };

  const postCoach = (coach) => {
    if (isNaN(coach.instructor_id)) {
      CoachesService.postCoach(coach).then(() => {
        success("¡Coach guardada con éxito!");
        getCoaches();
        hideModal();
      });
    } else {
      CoachesService.putCoach(coach).then(() => {
        success("¡Coach guardada con éxito!");

        getCoaches();
        hideModal();
      });
    }
  };

  const deleteCoach = (instructor_id) => {
    CoachesService.deleteCoach(instructor_id).then(() => {
      hideModal();
      getCoaches();
    });
  };

  return (
    <CoachesContext.Provider
      value={{
        ...state,
        getCoach,
        setCoach,
        postCoach,
        getCoaches,
        createCoach,
        deleteCoach,
        setPropiedadCoach,
      }}
    >
      {children}
    </CoachesContext.Provider>
  );
};
