import React, { useContext, useEffect } from "react";
import { CheckoutContext } from "../context/CheckoutContext";
import { AuthContext } from "../context/AuthContext";
import CheckoutHeader from "../components/checkout/CheckoutHeader";
import CheckoutFooter from "../components/checkout/CheckoutFooter";
import CheckoutClassPackage from "../components/checkout/CheckoutClassPackage";
import CheckoutDiscount from "../components/checkout/CheckoutDiscount";
import CheckoutAccount from "../components/checkout/CheckoutAccount";
import CheckoutPaymentMethods from "../components/checkout/CheckoutPaymentMethods";
import { Link } from "@reach/router";

const Checkout = ({ class_package_id }) => {
  const { user } = useContext(AuthContext);

  //Checkout
  const {
    paquete,
    descuento,
    setPayPal,
    createOrder,
    setDescuento,
    discountCode,
    class_package,
    setPaymentSource,
    payment_source_id,
  } = useContext(CheckoutContext);

  useEffect(() => {
    return () => {
      setDescuento(null);
    };
  }, []);

  useEffect(() => {
    if (descuento !== null && class_package !== null) {
      setPayPal(class_package_id, discountCode);
    }
  }, [descuento]);

  useEffect(() => {
    const paypalButton = document.getElementById("paypal-button");
    if (user !== null && class_package !== null && paypalButton !== null) {
      setPayPal(class_package_id);
    }
  }, [user, class_package]);

  const handleSubmit = (payment_source_id) => {
    let discount =
      descuento && descuento !== null ? descuento.code : discountCode;
    createOrder(class_package_id, payment_source_id, discount);
  };

  const renderBillingDisclaimer = () => {
    if (paquete && paquete !== null) {
      if (paquete.subscription_period !== null) {
        return (
          <div className="card p-4 border br-10 no-scale mb-4">
            <h4>Recuerda</h4>
            <p>
              * Tu suscripción se renovará automáticamente cada mes hasta que la
              canceles. Puedes cancelar en cualquier momento. Los cobros por las
              suscripciones no son reembolsables. Al realizar tu compra nos
              autorizas a cobrar a través de tu tarjeta de crédito o débito,
              aceptas nuestros <Link to="/terminos-y-condiciones">términos y condiciones</Link>.
            </p>
            <p></p>
          </div>
        );
      }
    }
  };

  return (
    <div className="container-fluid">
      <CheckoutHeader />
      <div className="container">
        <div className="row pt-4 pb-5">
          <div className="col-12 col-md-4 my-2">
            <CheckoutClassPackage class_package_id={class_package_id} />
            <CheckoutDiscount class_package_id={class_package_id} />
          </div>
          <div className="col-12 col-md-8 my-2">
            <CheckoutAccount />
            <CheckoutPaymentMethods
              class_package_id={class_package_id}
              paymentMethod={payment_source_id}
              setPaymentMethod={setPaymentSource}
              handleSubmitCheckout={handleSubmit}
            />
          </div>
        </div>
        {renderBillingDisclaimer()}
      </div>
      <CheckoutFooter />
    </div>
  );
};

export default Checkout;
