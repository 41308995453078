import { SHOW_SUCCESS, SHOW_ALERT, CLEAR_ALERT, CLEAR_SUCCESS } from "../types";
import moment from "moment";

moment.locale("es", {
  monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split("_"),
});

const stripe_dev_key =
  "pk_test_51LfuZgE9ZKfE9imp8ofFB7LW0jJiIBAxKY0hVXGerFBFcvKYw9SizwRjxW02OenPAmIo1z0fqDYQd9aOh7Rfja2300OT1xMzv6";

const stripe_live_key =
  "pk_live_51LfuZgE9ZKfE9impVSQd1amMp5g7NxlPAMUznuCBuiVIYROGuv4KUeccOrpgmHJguANojLxgB9Dx26BsvoFkvgOB00ALmrZr6F";

export const STRIPE_KEY =
  process.env.NODE_ENV === "development" ? stripe_dev_key : stripe_live_key;

export const API_KEY = "AIzaSyD_0F-t4VQvP9WHWcb7-E90M9WGzWHLUbU";

export const CONEKTA_API_KEY =
  process.env.NODE_ENV === "development"
    ? "key_CrAb2j5eNd4g9GBWHszJjYA"
    : "key_aFqb9ciyX7Bkp8JTTMPsqmQ";

export const displaySuccess = (dispatch, message) => {
  dispatch({ type: SHOW_SUCCESS, payload: message });
  setTimeout(() => {
    dispatch({ type: CLEAR_SUCCESS });
  });
};

export const displayError = (dispatch, error) => {
  if (typeof error === "object") {
    error = error.toString();
  }
  dispatch({ type: SHOW_ALERT, payload: error });
  setTimeout(() => dispatch({ type: CLEAR_ALERT }), 3000);
};

export const BASE_URL = "https://latina.disrupcion.mx/api";
  // process.env.NODE_ENV === "development"
  //   ? "http://localhost:4000/api"
  //   : "https://athome.latinatribe.mx/api";

export const S3_ENDPOINT = "https://latinatribe.s3.us-west-1.amazonaws.com";

export const searchRows = (query, rows) => {
  if (!rows) return;
  if (isNaN(query)) query = query.toLowerCase();
  let searchResult = rows.filter((row) => {
    let result = Object.keys(row).filter((column) => {
      if (Array.isArray(row[column])) {
        return row[column].filter((subcolumn) => {
          if (isNaN(subcolumn)) {
            if (subcolumn.toLowerCase().includes(query)) return row;
          } else if (subcolumn === query) return row;
          return null;
        });
      }
      if (isNaN(row[column])) {
        if (String(row[column]).toLowerCase().includes(query)) {
          return row;
        }
      } else if (String(row[column]) === query) {
        return row;
      } else if (Number(row[column]) === Number(query)) {
        return row;
      }
      return null;
    });
    return result.length > 0;
  });
  return searchResult;
};

export const getArgs = (args) => {
  if (args && args !== null) {
    const array = Object.keys(args)
      .map((key) => {
        if (args[key] && args[key] !== null && args[key] !== "") {
          return `${key}=${args[key]}`;
        }
        return null;
      })
      .filter((arg) => arg !== null);
    if (array.length > 0) {
      return `&${array.join("&")}`;
    }
  }
  return "";
};

export const calcularTotal = (productos) => {
  if (productos && productos !== null) {
    let total = 0;
    productos.forEach((producto) => {
      total += producto.precio * producto.cantidad;
    });
    return total;
  }
  return 0;
};

export function formatMonto(monto) {
  monto = parseFloat(monto);
  if (!monto || monto === null || isNaN(monto)) monto = 0;
  return numberWithCommas(monto);
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const hideModal = () => {
  const button = document.getElementById("main-button");
  if (button && button !== null) {
    button.click();
  }
};

export const showModal = () => {
  const button = document.getElementById("main-button");
  if (button && button !== null) {
    button.click();
  } else {
    const newButton = document.createElement("button");
    newButton.attributes.href = "#modal";
    newButton.id = "main-button";
    newButton.setAttribute("data-toggle", "modal");
    newButton.setAttribute("data-target", "#modal");
    newButton.setAttribute("data-bs-toggle", "modal");
    newButton.setAttribute("data-bs-target", "#modal");
    newButton.style.visibility = "hidden";
    document.body.appendChild(newButton);
    newButton.click();
  }
};

export const misclases = [
  {
    name: "Online",
    handle: "/mylatina/online",
  },
  {
    name: "Presenciales",
    handle: "/mylatina/presenciales",
  },
];

export const shop = [
  {
    name: "Clases",
    handle: "/mylatina/shop",
  },
  {
    name: "Merch",
    handle: "/mylatina/merch",
  },
];

export const cuenta = [
  {
    name: "Mi Información",
    handle: `/mylatina/informacion`,
  },
  {
    name: "Mis Métodos de Pago",
    handle: `/mylatina/metodos-pago`,
  },
  {
    name: "Mis Compras",
    handle: `/mylatina/pagos`,
  },
];

export const randomString = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getCardType = (number) =>
  String(number) === "3"
    ? "amex"
    : String(number) === "4"
      ? "visa"
      : "mastercard";

export const categories = {
  dance: [11, 12, 13, 15],
  workout: [14, 15],
  presenciales: [11742, 11743, 11744, 11745],
};

export const getCompradasPresenciales = (paquetes) => {
  const package_ids = categories.presenciales;
  const customer_classes = paquetes.filter((class_category) =>
    package_ids.includes(class_category.package_class_id)
  );
  let total = 0;
  customer_classes.forEach((purchase) => {
    total += purchase.available_class;
  });
  return total;
};

export const getVigencia = (handle, user) => {
  const paquete = user.vigencias.find(
    (class_category) => class_category.handle === handle
  );
  if (paquete) return paquete.expiration;
};

export const isManager = (user) => {
  return user.isManager !== null && user.isManager;
};

export const isAdmin = (user) => {
  return user.isAdmin !== null && user.isAdmin;
};

export const isCoach = (user) => {
  return user.instructor_id !== null && user.instructor_id;
};

export const getLocalMoment = (datetime) => {
  return moment(
    moment(datetime).utc().format("YYYY-MM-DD HH:mm:ss"),
    "YYYY-MM-DD HH:mm:ss"
  );
};

export const getClassAvailableSpaces = (singleClass) => {
  return singleClass.capacity - singleClass.class_reservations.length;
};

export const userInformatinCompleted = (user) => {
  let valid = true;
  if (String(user.phone).length < 10) valid = false;
  if (user.last_name === "" || user.last_name === null) valid = false;
  return valid;
};

export const getLocalMomentDiff = (datetime, unitOfTime) => {
  return moment().diff(getLocalMoment(datetime), unitOfTime);
};

export const getValue = (object, key, type) => {
  if (object && object !== null) {
    const value = object[key];
    if (value && value !== null && value !== "") {
      if (type === "boolean") {
        return value === true || parseInt(value) === 1 || value === "true";
      } else if (type === "date") {
        return moment(value).utc().format("YYYY-MM-DD");
      }
      return value;
    }
  }
  if (type === "boolean") return false;
  return "";
};

export const getLastCategoryAccess = (category_accesses, class_category_id) => {
  let category_access = category_accesses
    .filter(
      (category) =>
        parseInt(category.class_category_id) === parseInt(class_category_id)
    )
    .sort((a, b) =>
      moment(a.expiration_date).isBefore(moment(b.expiration_date)) ? 1 : -1
    );
  category_access = category_access[0];
  return category_access;
}