import React, { useContext, useEffect } from "react";
import PaqueteCard from "../components/paquetes/PaquetesCard";
import { navigate } from "@reach/router";
import { ClassClassPackageContext } from "../context/ClassPackageContext";

const Shop = () => {
  const { paquetes, getPaquetesStudio } = useContext(ClassClassPackageContext);

  useEffect(() => {
    getPaquetesStudio();
  }, []);

  const renderPaqueteOnline = () => {
    if (paquetes && paquetes !== null) {
      return paquetes.map((paquete) => (
        <PaqueteCard
          key={paquete.class_package_id}
          paquete={paquete}
          size="sm"
          action={() => {
            navigate(`/checkout/${paquete.class_package_id}`);
          }}
        />
      ));
    }
    return <div className="spinner-border mx-2" role="status"></div>;
  };

  return (
    <div className="container-fluid px-0">
      <h1 className="pb-3 mb-4 border-bottom">Paquetes</h1>
      <div className="row">{renderPaqueteOnline()}</div>
    </div>
  );
};

export default Shop;
