import api from "./api";

const route = "/single_class";

export default {
  getWeeks: (start_date, end_date) =>
    api.get(`${route}?start_date=${start_date}&end_date=${end_date}`),
  getClase: (class_instructor_id) =>
    api.get(`${route}/single/${class_instructor_id}`),
  getClases: (fechaInicio, fechaFin) =>
    api.get(`${route}/admin?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`),
  getCoachClasses: (fechaInicio, fechaFin) =>
    api.get(`${route}/coach?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`),
  getAsistentes: (class_instructor_id) =>
    api.get(`${route}/admin/asistentes/${class_instructor_id}`),
  postReservacion: (clase) =>
    api.post(`${route}/class_reservation`, { ...clase }),
  cancelReservacion: (class_reservation_id) =>
    api.delete(`${route}/${class_reservation_id}`),
  postClase: (clase) => api.post(route, { ...clase }),
  putClase: (clase) => api.put(route, { ...clase }),
  postAttend: (class_reservation_id, attend) =>
    api.post(`${route}/attend`, { class_reservation_id, attend }),
  postPayment: (class_reservation_id, is_paid) =>
    api.post(`${route}/payment`, { class_reservation_id, is_paid }),
  postAsistenteClase: (customer_id, class_instructor_id, is_cash, is_paid) =>
    api.post(`${route}/asistente`, {
      customer_id,
      class_instructor_id,
      is_cash,
      is_paid,
    }),
  deleteClase: (class_instructor_id) =>
    api.delete(`${route}/classInstructor/${class_instructor_id}`),
  deleteAsistente: (class_reservation_id) =>
    api.delete(`${route}/asistente/${class_reservation_id}`),
};
