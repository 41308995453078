import React from "react";
import { formatMonto } from "../../utils";

const PaqueteCard = ({
  size,
  paquete,
  action,
  buttonTitle,
  className,
  is_special_event,
}) => {
  const onSale = () => paquete.sale_price && paquete.sale_price !== null;

  const renderClassPackageTypes = () => {
    if (Array.isArray(paquete.class_package_types)) {
      if (paquete.class_package_types.length > 0) {
        return paquete.class_package_types.map(
          ({ expiration_days, class_amount, class_type }) => (
            <div key={class_type.class_type_id}>
              <p className="text-primary mb-0 bold">
                {class_amount} clases de {class_type.name}.
              </p>
              <p className="text-white">{expiration_days} días de vigencia.</p>
            </div>
          )
        );
      }
      
      return (
        <div>
          {paquete.class_amount > 0 && <p className="text-primary mb-0 bold">
            {paquete.class_amount} clases de Power Moves.
          </p>}
          {paquete.expiration_days > 0 && <p className="text-white">{paquete.expiration_days} de vigencia.</p>}
        </div>
      );
    }
  };

  const renderDescription = () => {
    if (paquete.description !== null && paquete.description !== "") {
      return <p className="package-description">{description}</p>;
    }
  };

  const renderShortDescription = () => {
    if (
      paquete.short_description !== null &&
      paquete.short_description !== ""
    ) {
      return <p className="pb-2 mb-0">{short_description}</p>;
    }
  };

  const { title, price, sale_price, description, short_description } = paquete;

  return (
    <div
      className={`pb-5 col-12 col-md-${size === "sm" ? "3" : "4"} col-lg-${
        size === "sm" ? "3" : "4"
      }`}
    >
      <div
        className={
          (is_special_event ? "bg-dark text-white border-primary " : "") +
          `card package-card shadow-sm no-scale position-relative ${
            onSale() ? "border-danger" : ""
          }`
        }
      >
        {sale_price && sale_price !== null && (
          <div className="bg-danger text-white sale-ribbon p-2 px-4 shadow-sm">
            SALE
          </div>
        )}
        <h2 className="border-bottom pb-2 mb-2">{title}</h2>
        {renderShortDescription()}
        {renderDescription()}
        {renderClassPackageTypes()}
        <div
          style={{
            position: "absolute",
            bottom: 20,
            width: "calc(100% - 48px)",
          }}
        >
          <p className="card-title">
            {onSale() && (
              <span className="strike-through d-inline-block me-3 text-danger">
                {"$"}
                {formatMonto(price)}
              </span>
            )}
            {"$"}
            {formatMonto(onSale() ? sale_price : price)}
            {" MXN"}
          </p>
          <button
            className={
              className ? className : "btn btn-primary btn-block w-100"
            }
            onClick={action}
          >
            {buttonTitle ? buttonTitle : "Comprar"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaqueteCard;
