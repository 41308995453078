import React from "react";
import PurchaseCard from "./PurchaseCard";

const PurchasesTable = ({ purchases, cancelSubscription }) => {
  const renderPurchases = () => {
    if (Array.isArray(purchases)) {
      return purchases.map((purchase) => (
        <PurchaseCard
          key={purchase.purchase_id}
          purchase={purchase}
          cancelSubscription={cancelSubscription}
        />
      ));
    }
  };
  return (
    <div className="table-responsive">
      <table className="table table-hover text-white">
        <thead className="border bold mx-0 py-2">
          <tr>
            <td className="bold">Folio</td>
            <td className="bold">Paquete</td>
            <td className="bold">Total</td>
            <td className="bold">Fecha</td>
            <td className="bold">Duración</td>
            <td className="bold">Estado</td>
            <td className="bold">Sig. Cargo</td>
            <td className="bold">Expira</td>
            <td className="bold">Acciones</td>
          </tr>
        </thead>
        <tbody>{renderPurchases()}</tbody>
      </table>
    </div>
  );
};

export default PurchasesTable;
