import React, { createContext, useReducer, useContext } from "react";
import OrdenesReducer from "../reducers/OrdenesReducer";
import OrdenesService from "../services/OrdenesService";
import {
  HIDE_SPINNER,
  ORDENES_RECIBIDAS,
  SET_ORDEN,
  SHOW_SPINNER,
} from "../types";
import { hideModal } from "../utils";
import { ModalContext } from "./ModalContext";

const initialState = {
  ordenes: null,
  orden: null,
  spinner: false,
};

export const OrdenesContext = createContext(initialState);

export const OrdenesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(OrdenesReducer, initialState);

  const { success } = useContext(ModalContext);

  const getOrdenes = () => {
    OrdenesService.getOrdenes().then((res) => {
      const { purchases } = res.data;
      dispatch({ type: ORDENES_RECIBIDAS, payload: purchases });
    });
  };

  const getOrden = (payment_id) => {
    OrdenesService.getOrden(payment_id).then((res) => {
      const { purchase } = res.data;
      dispatch({ type: SET_ORDEN, payload: purchase });
    });
  };

  const postRefund = (payment_id) => {
    dispatch({ type: SHOW_SPINNER });
    OrdenesService.postRefund(payment_id).then(() => {
      success("¡Reembolso exitoso!");
      dispatch({ type: HIDE_SPINNER });
      hideModal();
    });
  };

  return (
    <OrdenesContext.Provider
      value={{ ...state, getOrden, getOrdenes, postRefund }}
    >
      {children}
    </OrdenesContext.Provider>
  );
};
