import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import moment from "moment";
import { S3_ENDPOINT } from "../utils";
import BirthdateInput from "../components/common/BirthdateInput";

const MiInformacion = () => {
  const [editMode, setEditMode] = useState(false);
  const [changeImage, setChangeImage] = useState(false);
  const [picture, setPicture] = useState(null);

  const { user, getUsuario, updateUsuario, setPropiedadUser } =
    useContext(AuthContext);

  useEffect(() => {
    if (user !== null) {
      if (user.picture && user.picture !== null && picture === null) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setPicture(e.target.result);
        };
        reader.readAsDataURL(user.picture);
      }
    }
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setEditMode(false);
    updateUsuario(user);
  };

  const handleFiles = (e) => {
    const file = e.target.files[0];
    setPropiedadUser("picture", file);
  };

  const renderImage = () => {
    if (picture !== null) {
      return (
        <div className="col-4 col-md-4">
          <img
            src={picture}
            className="video-form-thumbnail d-block my-3"
            alt="Profile"
          />
        </div>
      );
    }
  };

  const renderImageForm = () => {
    if (changeImage || isNaN(user.file_id) || user.file_id === null) {
      return (
        <input
          type="file"
          className="form-control mb-3"
          onChange={handleFiles}
        />
      );
    }
    return (
      <div className="row">
        <div className="col-4">
          <img
            src={`${S3_ENDPOINT}/${user.file.name}.${user.file.type}`}
            className="mw-100 w-100 d-block m-auto"
          />
        </div>
        <div className="col-6">
          <button
            className="btn btn-outline-light me-3"
            onClick={() => setChangeImage(true)}
          >
            Cambiar Foto
          </button>
        </div>
      </div>
    );
  };

  const renderInformacion = () => {
    const { file, name, last_name, birthdate, instagram, phone, email } = user;
    if (editMode) {
      return (
        <form onSubmit={handleSubmit}>
          <div className="row">
            {renderImage()}
            <div className="col-8 col-md-8">
              <label>Foto</label>
              {renderImageForm()}
            </div>
          </div>
          <label>Nombre:</label>
          <input
            type="text"
            className="form-control mb-3"
            value={name}
            onChange={(e) => setPropiedadUser("name", e.target.value)}
          />
          <label>Apellidos:</label>
          <input
            type="text"
            className="form-control mb-3"
            value={last_name}
            onChange={(e) => setPropiedadUser("last_name", e.target.value)}
          />
          <label>Instagram:</label>
          <input
            type="text"
            className="form-control mb-3"
            value={instagram}
            onChange={(e) => setPropiedadUser("instagram", e.target.value)}
          />
          <label>Fecha de Nacimiento:</label>
          <BirthdateInput
            value={birthdate}
            modifier={(value) => setPropiedadUser("birthdate", value)}
          />
          <label>Telefono Celular</label>
          <input
            type="tel"
            className="form-control mb-3"
            value={phone}
            onChange={(e) => setPropiedadUser("phone", e.target.value)}
          />
          <div className="row mt-2">
            <div className="col col-md-6">
              <button type="submit" className="btn btn-primary">
                Guardar
              </button>
            </div>
            <div className="col col-md-6 text-end">
              <button
                className="btn btn-link text-muted"
                onClick={() => {
                  setEditMode(false);
                  getUsuario();
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </form>
      );
    }
    return (
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col col-md-4">
            <img
              src={
                file && file !== null
                  ? `${S3_ENDPOINT}/${file.name}.${file.type}`
                  : `${S3_ENDPOINT}/Mi-Informacion.png`
              }
              className="w-100 profile-image"
              alt="Profile"
            />
          </div>
          <div className="col col-md-8">
            <h4 className="mb-3 pb-3 border-bottom">
              {name} {last_name}
            </h4>
            <div>
              <i className="fa fa-birthday-cake me-3"></i>
              {moment(birthdate).format("DD MMM YYYY")}
            </div>
            <div>
              <i className="fa fa-envelope me-3"></i>
              {email}
            </div>
            <div>
              <i className="fa fa-phone me-3"></i>
              {phone}
            </div>
            <div>
              <i className="fab fa-instagram me-3"></i>
              {instagram}
            </div>
            <button
              className="btn btn-outline-primary mt-3"
              onClick={() => setEditMode(true)}
            >
              <i className="fa fa-edit"></i> Editar
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <h1>Mi Información</h1>
      <div className="card p-3 shadow-sm no-scale">{renderInformacion()}</div>
    </div>
  );
};

export default MiInformacion;
