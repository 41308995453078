import React from "react";
import latina_logo from "../../assets/images/latina_logo1.png";
import mastercard from "../../assets/images/mastercard.png";
import paypal from "../../assets/images/paypal.png";
import amex from "../../assets/images/amex.png";
import visa from "../../assets/images/visa.png";
import { Link } from "@reach/router";

const Footer = () => {
  return (
    <div className="footer bg-dark py-5">
      <div className="container text-white mt-5">
        <div className="row">
          <div className="col-12 col-md-4 my-3">
            <a href="https://latinatribe.mx" className="mb-3">
              <img src={latina_logo} alt="logo latina" className="logo-footer" />
            </a>
            <Link
              to="/terminos-y-condiciones"
              className="d-block my-3 text-white no-decoration"
            >
              Términos y Condiciones
            </Link>
          </div>
          <div className="col-12 col-md-4 my-3">
            <h4 className="text-primary text-montserrat bold">CONTACTO</h4>
            <p>
              <i className="fa fa-map-marker-alt me-2 text-primary"></i>
              Monterrey, Nuevo León.
            </p>
            <p>
              <i className="fa fa-envelope text-primary me-2"></i>{" "}
              contacto@latinatribe.mx
            </p>
          </div>

          <div className="col-12 col-md-4 my-3">
            <h4 className="text-primary text-montserrat bold">ACEPTAMOS</h4>
            <img src={amex} alt="amex" className="payment-method me-2" />
            <img src={mastercard} alt="mastercard" className="payment-method me-2" />
            <img src={visa} alt="visa" className="payment-method me-2" />
            <img src={paypal} alt="paypal" className="payment-method me-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
