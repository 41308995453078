import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { S3_ENDPOINT } from "../utils";
import { Link } from "@reach/router";
import BirthdateInput from "../components/common/BirthdateInput";

const EditInformacion = () => {
  const [changeImage, setChangeImage] = useState(false);
  const [picture, setPicture] = useState(null);

  const { user, getUsuario, updateUsuario, setPropiedadUser } =
    useContext(AuthContext);

  useEffect(() => {
    getUsuario();
  }, []);

  useEffect(() => {
    if (user !== null) {
      if (user.picture && user.picture !== null && picture === null) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setPicture(e.target.result);
        };
        reader.readAsDataURL(user.picture);
      }
    }
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUsuario(user);
  };

  const handleFiles = (e) => {
    const file = e.target.files[0];
    setPropiedadUser("file", file);
  };

  const renderImage = () => {
    if (picture !== null) {
      return (
        <div className="col-4 col-md-4">
          <img
            src={picture}
            className="video-form-thumbnail d-block my-3"
            alt="Profile"
          />
        </div>
      );
    }
  };

  const renderImageForm = () => {
    if (changeImage || isNaN(user.file_id) || user.file_id === null) {
      return (
        <input
          type="file"
          className="form-control mb-3"
          onChange={handleFiles}
        />
      );
    }
    return (
      <div className="row">
        <div className="col-4">
          <img
            src={`${S3_ENDPOINT}/${user.file.name}.${user.file.type}`}
            className="mw-100 w-100 d-block m-auto"
          />
        </div>
        <div className="col-6">
          <button
            className="btn btn-outline-light me-3"
            onClick={() => setChangeImage(true)}
          >
            Cambiar Foto
          </button>
        </div>
      </div>
    );
  };

  const renderInformacion = () => {
    if (user !== null) {
      const { name, last_name, birthdate, instagram, phone } = user;
      return (
        <form onSubmit={handleSubmit}>
          <div className="row">
            {renderImage()}
            <div className="col-8 col-md-8">
              <label>Foto</label>
              {renderImageForm()}
            </div>
          </div>
          <label>Nombre:</label>
          <input
            type="text"
            className="form-control mb-3"
            value={name}
            onChange={(e) => setPropiedadUser("name", e.target.value)}
          />
          <label>Apellidos:</label>
          <input
            type="text"
            className="form-control mb-3"
            value={last_name}
            onChange={(e) => setPropiedadUser("last_name", e.target.value)}
          />
          <label>Instagram:</label>
          <input
            type="text"
            className="form-control mb-3"
            value={instagram}
            onChange={(e) => setPropiedadUser("instagram", e.target.value)}
          />
          <label>Fecha de Nacimiento:</label>
          <BirthdateInput
            value={birthdate}
            modifier={(value) => setPropiedadUser("birthdate", value)}
          />
          <label>Telefono Celular</label>
          <input
            type="tel"
            className="form-control mb-3"
            value={phone}
            onChange={(e) => setPropiedadUser("phone", e.target.value)}
          />
          <div className="row mt-2">
            <div className="col col-md-6">
              <button type="submit" className="btn btn-dark">
                Guardar
              </button>
            </div>
            <div className="col col-md-6 text-end">
              <Link
                className="btn btn-link text-muted"
                to={
                "/mylatina/informacion"
                }
              >
                Cancelar
              </Link>
            </div>
          </div>
        </form>
      );
    }
  };

  return (
    <div className="container-fluid">
      <h1>Editar Información</h1>
      <div className="card p-3 shadow-sm no-scale">{renderInformacion()}</div>
    </div>
  );
};

export default EditInformacion;
