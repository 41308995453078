import { Link } from "@reach/router";
import React from "react";

const CheckoutFooter = () => {
  return (
    <div className="container">
      <div className="row bg-dark text-white py-2">
        <div className="col col-md-6">
          <Link to="/privacidad" className="text-white">
            Aviso de Privacidad
          </Link>
        </div>
        <div className="col col-md-6"></div>
      </div>
    </div>
  );
};

export default CheckoutFooter;
