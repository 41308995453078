import { Link } from "@reach/router";
import React from "react";
import logo_latina from "../../assets/images/latina_logo1.png";

const CheckoutHeader = () => {
  return (
    <div className="row border-bottom align-items-center pb-3 bg-dark pt-3">
      <div className="container-fluid">
        <div className="container px-2">
          <div className="row align-items-center">
            <div className="col-8 col-md-10">
              <h1 className="text-white display-4 mb-0">Checkout</h1>
            </div>
            <div className="col-4 col-md-2">
              <Link to="/">
                <img src={logo_latina} className="mw-100 w-100" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutHeader;
