import React, { useContext, useEffect } from "react";
import OrdenCard from "../components/ordenes/OrdenCard";
import { OrdenesContext } from "../context/OrdenesContext";
import { AuthContext } from "../context/AuthContext";
import { getVigencia } from "../utils";

const Vencimientos = () => {
  const { user } = useContext(AuthContext);
  const { ordenes, getOrdenes } = useContext(OrdenesContext);

  useEffect(() => {
    getOrdenes();
  }, []);

  const renderVencimientos = () => {
    return ["dance", "workout"].map((tipo) => {
      let vigencia = getVigencia(tipo, user);
      if (!vigencia) vigencia = "N/D";
      return (
        <div className="row" key={tipo}>
          <div className="col col-md-6">
            {String(tipo)[0].toUpperCase() + String(tipo).substring(1)}
          </div>
          <div className="col col-md-6">{vigencia}</div>
        </div>
      );
    });
  };

  const renderPresenciales = () => {
    if (ordenes && ordenes !== null) {
      if (ordenes.length === 0) {
        return <p>No has hecho ninguna compra.</p>;
      }
      let ordenesRender = ordenes.filter(
        (orden) => !orden.is_virtual_class && !orden.include_online_membership
      );
      if (ordenesRender.length === 0) {
        return <p>No has comprado clases presenciales.</p>;
      }
      return ordenesRender.map((orden) => (
        <OrdenCard key={orden.payment_id} payment={orden} />
      ));
    }
  };

  return (
    <div className="container-fluid">
      <div className="mx-0 row align-items-center pb-3 mb-3 border-bottom">
        <div className="container-fluid px-0">
          <h2>Mis Vencimientos</h2>
        </div>
      </div>
      <div className="card no-scale my-3">
        <div className="row">
          <div className="col col-md-6 bold">Tipo de Clase Online</div>
          <div className="col col-md-6 bold">Vence en</div>
        </div>
        {renderVencimientos()}
      </div>
      <div className="row">
        <div className="col col-md-6 bold">Paquete Presencial</div>
        <div className="col col-md-6 bold">Vence en</div>
      </div>
      {renderPresenciales()}
    </div>
  );
};

export default Vencimientos;
