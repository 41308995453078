import React from "react";
import latina_logo from "../assets/images/Latina_Logo2.png";

const Loading = () => {
  return (
    <div className="container-fluid bg-dark vh-100">
      <div className="row vh-100 align-items-center">
        <div className="container-fluid text-center">
          <img src={latina_logo} className="d-block m-auto landing-logo" />
          <div className="spinner-border text-white my-3" />
        </div>
      </div>
    </div>
  );
};

export default Loading;
