import React from "react";
import { formatMonto } from "../../utils";
import moment from "moment";

const OrdenCard = ({ purchase }) => {
  const renderClassPackage = () => {
    if (purchase.class_package !== null) {
      return purchase.class_package.title;
    }
  };

  const renderStatus = () => {
    if (purchase.status === "cancelled" || purchase.status === "revoked") {
      return <span className="badge badge-pill bg-danger">Cancelada</span>;
    }
    if (purchase.status === "pending") {
      return (
        <span className="badge badge-pill bg-warning text-dark">
          Pago incompleto
        </span>
      );
    } else if (
      purchase.status === "success" ||
      purchase.status === "completed"
    ) {
      return <span className="badge badge-pill bg-success">Pagada</span>;
    }
    return (
      <span className="badge badge-pill bg-secondary text-capitalize">
        {purchase.status}
      </span>
    );
  };

  return (
    <div className="card p-3 no-scale my-2">
      <div className="row">
        <div className="col col-md-2">#{purchase.purchase_id}</div>
        <div className="col col-md-2">{renderClassPackage()}</div>
        <div className="col col-md-2">
          {"$"}
          {formatMonto(purchase.total_payment)}
          {" MXN"}
        </div>
        <div className="col col-md-2">{renderStatus()}</div>
        <div className="col col-md-2">
          {moment(purchase.createdAt).format("DD MMM YYYY HH:mm")}
        </div>
      </div>
    </div>
  );
};

export default OrdenCard;
