import React, { useContext, useEffect } from "react";
import { Router } from "@reach/router";
import Login from "./views/Login";
import { AuthContext } from "./context/AuthContext";
import Modal from "./components/global/Modal";
import ErrorAlert from "./components/common/ErrorAlert";
import SuccessAlert from "./components/common/SuccessAlert";
import Checkout from "./views/Checkout";
import ThankYou from "./views/ThankYou";
import SignUp from "./views/SignUp";
import Recuperar from "./views/Recuperar";
import Loading from "./views/Loading";
import AtHomePanel from "./views/AtHomePanel";
import AtHomeLanding from "./views/athome/AtHomeLanding";

const AtHome = () => {
  const { user, userLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    userLoggedIn();
    document.title = "Latina At Home";
    document.documentElement.style.setProperty(`--primary`, "#EDC0D3");
  }, []);

  const hasUser = () => {
    return user !== null;
  };

  return (
    <div>
      <Router>
        <AtHomeLanding path="/*" />
        <Login path="/login" />
        <SignUp path="/signup" />
        <Recuperar path="/recuperar" />
        <Checkout path="/checkout/:class_package_id/*" />
        <ThankYou path="/thankyou/:purchase_id/*" />
        {hasUser() ? (
          <AtHomePanel path="/mylatina/*" />
        ) : (
          <Loading path="/mylatina/*" />
        )}
      </Router>
      <Modal />
      <ErrorAlert />
      <SuccessAlert />
    </div>
  );
};

export default AtHome;
