import React from "react";
import { formatMonto, S3_ENDPOINT } from "../../utils";
import StatusBadge from "../global/StatusBadge";
import moment from "moment";

const PurchaseCard = ({ purchase, cancelSubscription }) => {
  const validClassPackage = () => {
    return purchase.class_package && purchase.class_package !== null;
  };

  const renderActions = () => {
    if (validClassPackage()) {
      return (
        <td>
          {renderFile()}
          {renderLink()}
          {renderCancel()}
        </td>
      );
    }
    return <td>{renderCancel()}</td>;
  };

  const renderFile = () => {
    if (
      purchase.class_package.downloadable &&
      purchase.class_package.downloadable !== null
    ) {
      return (
        <a
          href={`${S3_ENDPOINT}/${purchase.class_package.downloadable.name}.${purchase.class_package.downloadable.type}`}
          className="btn btn-sm btn-outline-secondary"
        >
          <i className="fa fa-file"></i>
        </a>
      );
    }
  };

  const renderLink = () => {
    if (
      purchase.class_package.file_url &&
      purchase.class_package.file_url !== null
    ) {
      return (
        <a
          target="_blank"
          href={purchase.class_package.file_url}
          className="btn btn-sm btn-outline-secondary"
        >
          <i className="fa fa-link"></i>
        </a>
      );
    }
  };

  const renderCancel = () => {
    if (purchase.subscription_id !== null) {
      if (purchase.status !== "cancelled" && purchase.status !== "revoked") {
        return (
          <button
            className="btn btn-outline-danger btn-sm"
            onClick={() => cancelSubscription(purchase)}
          >
            <i className="fa fa-times"></i>
          </button>
        );
      }
    }
  };

  const renderExpiration = () => {
    if (
      purchase.status !== "active" &&
      purchase.status !== "pending" &&
      purchase.status !== "revoked"
    ) {
      return moment(purchase.createdAt)
        .add(purchase.expiration_days, "days")
        .format("DD MMM YYYY HH:mm");
    }
  };

  const renderClassPackage = () => {
    if (validClassPackage()) {
      return purchase.class_package.title;
    }
  };

  const renderNextInvoice = () => {
    if (purchase.status === "active") {
      const initialMoment = moment(purchase.createdAt);
      if (purchase.free_trial_length !== null) {
        initialMoment
          .add(purchase.free_trial_length, purchase.free_trial_period)
          .format("YYYY-MM-DD");
        if (initialMoment.isAfter(moment())) {
          return initialMoment.format("DD MMM YYYY");
        }
      }
      const anchordate = initialMoment.date();
      const currentday = moment().date();
      const startDate = moment().startOf("month").add(anchordate, "days");
      if (currentday > anchordate) {
        return startDate
          .add(purchase.subscription_interval, purchase.subscription_period)
          .format("DD MMM YYYY");
      }
      return startDate.format("DD MMM YYYY");
    }
  };

  return (
    <tr>
      <td className="">#{purchase.purchase_id}</td>
      <td className="">{renderClassPackage()}</td>
      <td className="">
        {"$"}
        {formatMonto(purchase.total_payment)} {"MXN"}
      </td>
      <td className="">
        {moment(purchase.createdAt).format("DD MMM YYYY HH:mm")}
      </td>
      <td className="">{purchase.expiration_days} días</td>
      <td className="">
        <StatusBadge {...purchase} />
      </td>
      <td>{renderNextInvoice()}</td>
      <td className="">{renderExpiration()}</td>
      {renderActions()}
    </tr>
  );
};

export default PurchaseCard;
