import React, { useContext, useEffect } from "react";
import { VideosContext } from "../../context/VideosContext";

const SingleVideo = ({ video_id }) => {
  const { video, getSingleVideo, agregarFavorito, eliminarFavorito } =
    useContext(VideosContext);

  useEffect(() => {
    fetchVideo();
  }, []);

  const fetchVideo = () => {
    getSingleVideo(video_id);
  };

  const renderVideo = () => {
    if (video && video !== null) {
      return (
        <iframe
          className="video-iframe"
          src={`https://player.vimeo.com/video/${video.url}`}
          allowFullScreen
        ></iframe>
      );
    }
  };

  const isFavorito = () => {
    if (video && video !== null) {
      return video.favorites.length > 0;
    }
    return false;
  };

  return (
    <div className="container-fluid">
      <div className="row mb-4 pb-3 border-bottom mx-0">
        <div className="col-6 col-md-10 px-0">
          <h2>{video && video !== null ? video.name : "Video"}</h2>
        </div>
        <div className="col-6 col-md-2 text-right px-0">
          <button
            className={`btn shadow-sm ${
              isFavorito() ? "text-primary" : "text-white"
            }`}
            onClick={() => {
              if (!isFavorito()) {
                agregarFavorito(video.video_id, fetchVideo);
              } else {
                eliminarFavorito(video.video_id, fetchVideo);
              }
            }}
          >
            <i className="fas fa-heart"></i>
          </button>
        </div>
      </div>
      {renderVideo()}
    </div>
  );
};

export default SingleVideo;
