import React, { useContext, useEffect } from "react";
import PurchasesTable from "../components/purchases/PurchasesTable";
import CancelForm from "../components/purchases/CancelForm";
import { ModalContext } from "../context/ModalContext";
import { PurchasesContext } from "../context/PurchasesContext";

const Pagos = () => {
  const { purchases, getPurchases, cancelSubscription } =
    useContext(PurchasesContext);
  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getPurchases();
  }, []);

  const confirmCancel = (purchase) => {
    modalComponent(
      "Precaución",
      <CancelForm purchase={purchase} cancelSubscription={cancelSubscription} />
    );
  };

  const renderOrdenes = () => {
    if (Array.isArray(purchases)) {
      if (purchases.length === 0) {
        return <p>No has hecho ninguna compra.</p>;
      }
      return (
        <PurchasesTable
          purchases={purchases}
          cancelSubscription={confirmCancel}
        />
      );
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid">
      <div className="mx-0 row align-items-center pb-3 mb-3 border-bottom">
        <div className="container-fluid px-0">
          <h2>Mis Pagos</h2>
        </div>
      </div>
      {renderOrdenes()}
    </div>
  );
};

export default Pagos;
