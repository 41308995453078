import { CUSTOMERS_RECIBIDOS, SET_CUSTOMER } from "../types";

export default (state, { type, payload }) => {
  switch (type) {
    case CUSTOMERS_RECIBIDOS: {
      return { ...state, customers: payload };
    }
    case SET_CUSTOMER: {
      return { ...state, customer: payload };
    }
    default:
      return { ...state };
  }
};
