import React from "react";
import Main from "./Main";
import { CheckoutProvider } from "./context/CheckoutContext";
import { ClassCategoryProvider } from "./context/ClassCategoryContext";
import { ClassInstructorProvider } from "./context/ClassInstructorContext";
import { ClassTypeProvider } from "./context/ClassTypesContext";
import { CoachesProvider } from "./context/CoachesContext";
import { CustomerProvider } from "./context/CustomerContext";
import { DiscountsProvider } from "./context/DiscountsContext";
import { FiltersProvider } from "./context/FiltersContext";
import { HomeProvider } from "./context/HomeContext";
import { LocationsProvider } from "./context/LocationsContext";
import { MetodosProvider } from "./context/MetodosContext";
import { ModalProvider } from "./context/ModalContext";
import { OrdenesProvider } from "./context/OrdenesContext";
import { PaquetesProvider } from "./context/ClassPackageContext";
import { ReservationsProvider } from "./context/ReservationsContext";
import { UserProvider } from "./context/AuthContext";
import { VideosProvider } from "./context/VideosContext";
import { VideoTypeProvider } from "./context/VideoTypeContext";
import { PaymentSourcesProvider } from "./context/PaymentSourcesContext";
import { PurchaseProvider } from "./context/PurchasesContext";
import { combineComponents } from "./context";
import { WaitlistsProvider } from "./context/WaitlistsContext";
import { AppConfigProvider } from "./context/AppConfigContext";

const AppContextProvider = combineComponents([
  HomeProvider,
  VideosProvider,
  OrdenesProvider,
  FiltersProvider,
  CoachesProvider,
  MetodosProvider,
  PaquetesProvider,
  CheckoutProvider,
  PurchaseProvider,
  CustomerProvider,
  AppConfigProvider,
  DiscountsProvider,
  LocationsProvider,
  ClassTypeProvider,
  VideoTypeProvider,
  WaitlistsProvider,
  ReservationsProvider,
  ClassCategoryProvider,
  PaymentSourcesProvider,
  ClassInstructorProvider,
]);

function App() {
  return (
    <ModalProvider>
      <UserProvider>
        <AppContextProvider>
          <Main />
        </AppContextProvider>
      </UserProvider>
    </ModalProvider>
  );
}

export default App;
