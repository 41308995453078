import React, { useEffect, useContext } from "react";
import AtHomeSlideshow from "../../components/global/AtHomeSlideshow";
import PaqueteCard from "../../components/paquetes/PaquetesCard";
import AtHomeNavbar from "../../components/global/AtHomeNavbar";
import { ClassClassPackageContext } from "../../context/ClassPackageContext";
import Footer from "../../components/global/Footer";
import { Link, navigate } from "@reach/router";
import { S3_ENDPOINT } from "../../utils";
import AtHomeTrial from "../../components/modals/AtHomeTrial";

const AtHomeLanding = () => {
  const { paquetes, getPaquetesOnline } = useContext(ClassClassPackageContext);

  useEffect(() => {
    document.onscroll = handleScroll;
    getPaquetesOnline();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = (e) => {
    let navbar = document.getElementById("navbar");
    if (window.scrollY > 100 && navbar !== null) {
      navbar.style.backgroundColor = "#000";
    } else if (navbar !== null) {
      navbar.style.backgroundColor = "transparent";
    }
  };

  const toCheckout = (class_package_id) => {
    navigate(`/checkout/${class_package_id}`);
  };

  const renderOnline = () => {
    if (paquetes && paquetes !== null) {
      let paquetesRender = [...paquetes];
      paquetesRender = paquetesRender.filter(
        (paquete) => paquete.include_online
      );
      return paquetesRender.map((presencial) => (
        <PaqueteCard
          paquete={presencial}
          key={presencial.class_package_id}
          action={() => toCheckout(presencial.class_package_id)}
        />
      ));
    }
  };

  return (
    <div>
      <AtHomeTrial />
      <AtHomeNavbar />
      <div id="video" className="row video-row">
        <AtHomeSlideshow />
      </div>
      <div className="container" id="online">
        <div className="row py-5">
          <div className="col-12 col-md-6 px-0">
            <img
              src={`${S3_ENDPOINT}/LatinaStudio5.jpeg`}
              className="landing-class-type"
            />
          </div>
          <div className="col-12 col-md-6 bg-gray p-5">
            <img
              src={`${S3_ENDPOINT}/power-moves-logo.png`}
              className="mw-50 w-50 mb-4"
            />
            <p>
              Feel like a <i>diosa</i> while working out and having fun in our
              follow along dance cardio sessions. Tune into your <i>bichota</i>
              mindset to the beat of the top reggaeton and hip hop jams of the
              moment and of course, the classics.
            </p>
            <p>Es tu momento, baby girl, own it!</p>
            <Link to="/checkout/15" className="btn btn-primary mt-5">
              COMPRA TU PAQUETE ONLINE
            </Link>
          </div>
        </div>
      </div>
      <div id="shop" className="container-fluid py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <p className="montserrat bold h4">
                Your favorite classes available wherever you want,
              </p>
              <p className="montserrat bold h4">
                from any device. Just{" "}
                <span className="text-primary montserrat bold h4">
                  click play
                </span>{" "}
                and dance away!
              </p>
            </div>
            <div className="col-12 col-md-6">
              <img src={`${S3_ENDPOINT}/mockup.png`} className="mw-100 w-100" />
            </div>
          </div>
        </div>
      </div>
      <div id="paquetes" className="container pb-5">
        <h2>Paquetes de Clases</h2>
        <div className="row">{renderOnline()}</div>
      </div>
      <Footer />
    </div>
  );
};

export default AtHomeLanding;
