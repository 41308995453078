import React from "react";
import { S3_ENDPOINT } from "../../utils";
import { Link, navigate } from "@reach/router";

const LatestVideo = ({ video }) => {
  const getSrc = () => {
    if (video.file && video.file !== null) {
      return `${S3_ENDPOINT}/${video.file.name}.${video.file.type}`;
    }
  };

  return (
    <div
      className="card px-0 py-0 latest-card no-scale shadow-sm"
      onClick={() => navigate(`/mylatina/video/${video.video_id}`)}
    >
      <img
        src={getSrc()}
        className="latest-card-thumbnail"
        alt="Video thumbnail"
      />
      <div className="latest-card-overlay"></div>
      <div className="latest-card-content">
        <h2 className="border-bottom pb-2 mb-3">{video.name}</h2>
        <p>{video.description}</p>
        <Link
          className="btn btn-primary"
          to={`/athome/mylatina/video/${video.video_id}`}
        >
          <i className="fa fa-play-circle me-1"></i> PLAY ME
        </Link>
      </div>
    </div>
  );
};

export default LatestVideo;
