import React from "react";

const BichotaSeason = () => {
  return (
    <div className="container-fluid bg-black">
      <div className="container" style={{ maxWidth: 800 }}>
        <div className="card shadow my-3">
          <img
            src="https://latinatribe.s3.us-west-1.amazonaws.com/bichotaseason-confirmacion-min.png"
            className="mw-100 w-100 mb-3"
          />
          <h2>¡Ya estás dentro! BICHOTA SEASON challenge x Latina</h2>
          <p className="text-italic text-primary">
            Oh my God!
            <br /> This is so amazing!
            <br /> I am super happy, wow!{" "}
          </p>
          <p>
            Recibirás via correo electrónico las instrucciones a seguir para
            acceder al contenido del reto que estará dispo para todas en esta
            misma plataforma a partir del 4 feb.{" "}
          </p>
          <p>¡Arrancamos juntxs el lunes 5 de Febrero!</p>
          <p>
            Te explicaremos todo lo que necesitas saber sobre el contenido del
            reto aquí:{" "}
          </p>
          <ul>
            <li>Kickoff Zoom Session ft. Latina Tribe Founders</li>
            <li>Domingo 4 de Febrero</li>
            <li>7:00 PM (Mexico City)</li>
          </ul>
          <p>
            Mantente informada y acompañada durante este proceso, join our
            ✨💗🌈Besties🌈💗✨ group chat:
            <a
              target="_blank"
              className="mx-2"
              href="https://chat.whatsapp.com/Jx26xCjpciP7UBXV4sfXpS"
            >
              https://chat.whatsapp.com/Jx26xCjpciP7UBXV4sfXpS
            </a>
          </p>
          <p>Lista Mamiii? #BichotaSZNChallenge</p>
        </div>
      </div>
    </div>
  );
};

export default BichotaSeason;
