import React, { useEffect, useState } from "react";
import VideoCard from "./VideoCard";
import VideosService from "../../services/VideosService";
import moment from "moment";

const ClassTypeCarousel = ({
  class_category,
  user,
  agregarFavorito,
  eliminarFavorito,
}) => {
  const [videos, setVideos] = useState(null);

  useEffect(() => {
    VideosService.getVideos({
      class_category_id: class_category.class_category_id,
    }).then((res) => {
      setVideos(res.data.videos);
    });
  }, []);

  const renderExpiration = (class_category_id) => {
    let category_access = user.category_accesses.find(
      (category_access) =>
        category_access.class_category_id === class_category_id &&
        moment(category_access.expiration_date).isAfter(moment())
    );
    if (category_access) {
      return (
        <span className="badge badge-pill bg-primary text-dark">
          Expira en{" "}
          {moment(category_access.expiration_date).utc().format("DD MMM YYYY")}
        </span>
      );
    }
    if (user.user !== null) {
      return (
        <span className="badge badge-pill bg-verde text-dark">
          Acceso Ilimitado
        </span>
      );
    }
    return <span className="badge badge-pill bg-danger">Sin Acceso</span>;
  };

  const getVideos = () => {
    if (Array.isArray(videos)) {
      let videosRender = videos.filter(
        (video) =>
          parseInt(video.class_category_id) ===
          parseInt(class_category.class_category_id)
      );
      videosRender = videosRender.sort((a, b) =>
        moment(a.createdAt).isAfter(b.createdAt) ? 1 : -1
      );
      return videosRender;
    }
  };

  const renderVideos = () => {
    let videosRender = getVideos();
    if (Array.isArray(videosRender)) {
      return videosRender.map((video) => (
        <VideoCard
          video={video}
          hideFavoriteButton
          key={video.video_id}
          agregarFavorito={agregarFavorito}
          eliminarFavorito={eliminarFavorito}
        />
      ));
    }
  };

  const renderClassType = () => {
    if (getVideos()) {
      return (
        <>
          <div className="row mb-4 py-3 border-bottom align-items-center">
            <div className="col-6">
              <h3 className="cursor-pointer text-white bold no-decoration mb-0 pointer">
                {class_category.name}
              </h3>
            </div>
            <div className="col-6 text-right">
              {renderExpiration(class_category.class_category_id)}
            </div>
          </div>
          <div className="row">{renderVideos()}</div>
        </>
      );
    }
  };

  return <div className="container-fluid">{renderClassType()}</div>;
};

export default ClassTypeCarousel;
