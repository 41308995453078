import { Link } from "@reach/router";
import React, { useEffect } from "react";

const AtHomeTrial = () => {
  const open = true;

  useEffect(() => {
    showModal();
  }, []);

  const showModal = () => {
    const button = document.getElementById("fest-button");
    if (button && button !== null) {
      button.click();
    } else {
      const newButton = document.createElement("button");
      newButton.attributes.href = "#modal-fest";
      newButton.id = "fest-button";
      newButton.setAttribute("data-toggle", "modal");
      newButton.setAttribute("data-target", "#modal-fest");
      newButton.setAttribute("data-bs-toggle", "modal");
      newButton.setAttribute("data-bs-target", "#modal-fest");
      newButton.style.visibility = "hidden";
      document.body.appendChild(newButton);
      newButton.click();
    }
  };

  const hideModal = () => {
    const button = document.getElementById("fest-button");
    if (button && button !== null) {
      button.click();
    }
  };

  return (
    <div
      role="dialog"
      tabIndex="-1"
      id="modal-fest"
      className="modal fade"
      aria-labelledby="modal"
      aria-hidden={open ? "false" : "true"}
    >
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <img
            alt="at home trial"
            src="/images/athome-trial.jpeg"
            className="mw-100 w-100 m-auto"
          />
          <div className="row mx-0 my-3">
            <div className="col-6">
              <button className="btn text-muted w-100" onClick={hideModal}>
                No, thanks
              </button>
            </div>
            <div className="col-6">
              <Link
                to="/checkout/11779"
                onClick={hideModal}
                className="btn btn-primary w-100"
              >
                Get Access Now!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AtHomeTrial;
