import React, { useContext, useEffect } from "react";
import { VideoTypeContext } from "../../context/VideoTypeContext";
import { CoachesContext } from "../../context/CoachesContext";
import { VideosContext } from "../../context/VideosContext";
import { FiltersContext } from "../../context/FiltersContext";

const FilterSection = ({ page }) => {
  const {
    class_category_id,
    instructor_id,
    class_type_id,
    video_type_id,
    showFavorites,
    setFilter,
    query,
  } = useContext(FiltersContext);

  const { video_types, getVideoTypes } = useContext(VideoTypeContext);

  const { coaches, getCoaches } = useContext(CoachesContext);

  const {
    spinner,
    getVideos,
    clearReload,
    getFavoritos,
    reloadVideos,
    clearFavoritos,
  } = useContext(VideosContext);

  useEffect(() => {
    if (reloadVideos) {
      fetchVideos();
      clearReload();
    }
  }, [reloadVideos]);

  useEffect(() => {
    getCoaches();
    fetchVideos();
    getVideoTypes();
  }, []);

  useEffect(() => {
    if (showFavorites) {
      getFavoritos({
        instructor_id,
        video_type_id,
        query,
        page,
      });
    } else {
      clearFavoritos();
      fetchVideos();
    }
  }, [showFavorites]);

  useEffect(() => {
    if (!spinner) {
      fetchVideos();
    }
  }, [class_category_id, instructor_id, class_type_id, video_type_id, query]);

  const fetchVideos = () => {
    if (showFavorites) {
      getFavoritos({
        instructor_id,
        video_type_id,
        query,
        page,
      });
    } else {
      getVideos({
        instructor_id,
        video_type_id,
        query,
        page,
      });
    }
  };

  const renderCoaches = () => {
    if (coaches && coaches !== null) {
      return [
        <option key="todos" value="">
          Todo
        </option>,
        ...coaches.map((coach) => (
          <option key={coach.instructor_id} value={coach.instructor_id}>
            {coach.name} {coach.last_name}
          </option>
        )),
      ];
    }
  };

  const renderVideoTypes = () => {
    if (Array.isArray(video_types)) {
      return [
        <option key="todos" value="">
          Todos
        </option>,
        ...video_types.map((video_type) => (
          <option
            key={video_type.video_type_id}
            value={video_type.video_type_id}
          >
            {video_type.name}
          </option>
        )),
      ];
    }
  };

  return (
    <div className="filter-card card no-scale shadow-sm">
      <div className="row align-items-center">
        <div className="col-12 col-md-3 mb-3">
          <label>Buscar por palabra clave</label>
          <input
            type="text"
            className="form-control"
            value={query}
            placeholder="Buscar por nombre de clase..."
            onChange={(e) => setFilter("query", e.target.value)}
          />
        </div>
        <div className="col-12 col-md-3 mb-3">
          <label>Coach</label>
          <select
            className="form-control"
            value={instructor_id !== null ? instructor_id : ""}
            onChange={(e) => setFilter("instructor_id", e.target.value)}
          >
            {renderCoaches()}
          </select>
        </div>
        <div className="col-12 col-md-3 mb-3">
          <label>Tipo</label>
          <select
            className="form-control"
            value={video_type_id !== null ? video_type_id : ""}
            onChange={(e) => setFilter("video_type_id", e.target.value)}
          >
            {renderVideoTypes()}
          </select>
        </div>
        <div className="col-12 col-md-3">
          <button
            className={`btn btn-light border ${
              showFavorites ? "text-danger" : "text-dark"
            }`}
            onClick={() => setFilter("showFavorites", !showFavorites)}
          >
            <i className="fa fa-heart me-1"></i>{" "}
            {showFavorites ? <span>Viendo</span> : ""} Favoritos
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterSection;
