import React, { useContext } from "react";
import { Router } from "@reach/router";
import Videos from "./athome/Videos";
import Shop from "./Shop";
import { AuthContext } from "../context/AuthContext";
import SingleVideo from "./athome/SingleVideo";
import MiInformacion from "./MiInformacion";
import MisMetodos from "./MisMetodos";
import Pagos from "./Pagos";
import Vencimientos from "./Vencimientos";
import PanelNavbar from "../components/global/AtHomePanelNavbar";
import EditInformacion from "./EditInformacion";
import Footer from "../components/global/Footer";
import AtHomeMobileMenu from "../components/common/AtHomeMobileMenu";

const AtHomePanel = () => {
  const { user, signOut } = useContext(AuthContext);

  return (
    <div className="container-fluid px-0">
      <AtHomeMobileMenu signOut={signOut} />
      <PanelNavbar user={user} signOut={signOut} />
      <div className="main-panel px-4">
        <div className="content overflow-x-hidden mt-5">
          <Router>
            <Videos path="/*" />
            <SingleVideo path="/video/:video_id" />
            <Shop path="/shop" />
            <MiInformacion path="/informacion" />
            <EditInformacion path="/informacion/edit" />
            <MisMetodos path="/metodos-pago" />
            <Pagos path="/pagos" />
            <Vencimientos path="/vencimientos/*" />
          </Router>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AtHomePanel;
