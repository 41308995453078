import React, { createContext, useContext, useReducer } from "react";
import OrdenesReducer from "../reducers/OrdenesReducer";
import InvoicesService from "../services/InvoicesService";
import PurchasesService from "../services/PurchasesService";
import { INVOICES_RECIBIDOS, ORDENES_RECIBIDAS, SET_ORDEN } from "../types";
import { hideModal } from "../utils";
import { ModalContext } from "./ModalContext";

const initialState = {
  purchases: null,
  purchase: null,
  invoices: null,
};

export const PurchasesContext = createContext(initialState);

export const PurchaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(OrdenesReducer, initialState);

  const { success } = useContext(ModalContext);

  const getPurchases = () => {
    PurchasesService.getPurchases().then((res) => {
      const { purchases } = res.data;
      dispatch({ type: ORDENES_RECIBIDAS, payload: purchases });
    });
  };

  const getInvoices = () => {
    InvoicesService.getInvoices().then((res) => {
      const { invoices } = res.data;
      dispatch({ type: INVOICES_RECIBIDOS, payload: invoices });
    });
  };

  const getPurchase = (purchase_id) => {
    PurchasesService.getPurchase(purchase_id).then((res) => {
      const { purchase } = res.data;
      dispatch({ type: SET_ORDEN, payload: purchase });
    });
  };

  const cancelSubscription = (purchase_id, reason) => {
    PurchasesService.cancelSubscription(purchase_id, reason).then((res) => {
      success("¡Suscripción cancelada!");
      setTimeout(getPurchases, 1500);
      getPurchases();
      hideModal();
    });
  };

  return (
    <PurchasesContext.Provider
      value={{
        ...state,
        getPurchase,
        getPurchases,
        getInvoices,
        cancelSubscription,
      }}
    >
      {children}
    </PurchasesContext.Provider>
  );
};
