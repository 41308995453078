import { Link } from "@reach/router";
import React, { useEffect, useState } from "react";
import { S3_ENDPOINT } from "../../utils";

const AtHomeSlideshow = () => {
  const [active, setActive] = useState(0);

  const slides = [
    {
      title: "Latina Fest",
      subtitle: "Ven a disfrutar con nosotras",
      text: "Cupo limitado a 120 lugares",
      src: `${S3_ENDPOINT}/LatinaHome2.jpeg`,
    },
    {
      title: "Latina Fest",
      subtitle: "Ven a disfrutar con nosotras",
      text: "Cupo limitado a 120 lugares",
      src: `${S3_ENDPOINT}/LatinaHome.jpeg`,
    },
    {
      title: "Latina Fest",
      subtitle: "Ven a disfrutar con nosotras",
      text: "Cupo limitado a 120 lugares",
      src: `${S3_ENDPOINT}/LatinaHome3.jpeg`,
    },
    {
      title: "Latina Fest",
      subtitle: "Ven a disfrutar con nosotras",
      text: "Cupo limitado a 120 lugares",
      src: `${S3_ENDPOINT}/LatinaHome4.jpeg`,
    },
    {
      title: "Latina Fest",
      subtitle: "Ven a disfrutar con nosotras",
      text: "Cupo limitado a 120 lugares",
      src: `${S3_ENDPOINT}/LatinaHome5.jpeg`,
    },
    {
      title: "Latina Fest",
      subtitle: "Ven a disfrutar con nosotras",
      text: "Cupo limitado a 120 lugares",
      src: `${S3_ENDPOINT}/LatinaHome6.jpeg`,
    },
    {
      title: "Latina Fest",
      subtitle: "Ven a disfrutar con nosotras",
      text: "Cupo limitado a 120 lugares",
      src: `${S3_ENDPOINT}/LatinaHome7.jpeg`,
    },
  ];
  useEffect(() => {
    let interval = setInterval(handleNext, 3000);
    return () => {
      window.clearInterval(interval);
    };
  }, []);

  const handleNext = () => {
    if (active === slides.length - 1) {
      setActive(0);
    } else {
      setActive(active + 1);
    }
  };

  const renderSlides = () => {
    if (Array.isArray(slides)) {
      return slides.map((slide, index) => (
        <div
          key={index}
          className={`carousel-item ${active === index ? "active" : ""}`}
        >
          <img
            className="d-block slideshow-img w-100 mw-100"
            src={slide.src}
            alt="First slide"
          />
          <div className="slideshow-text p-5">
            <img
              src={`${S3_ENDPOINT}/LatinaAtHome.png`}
              className="mb-4 slideshow-logo"
            />
            <div style={{ maxWidth: 450 }}>
              <p>
                Empower yourself, workout and have fun with more than 100 Power
                Moves and iWorkout on demand videos.
              </p>
            </div>
            <h1 className="display-3">Join The Tribe</h1>
            <div className="row">
              <div className="col-12 col-md-10 col-lg-8">
                <Link className="btn btn-primary mb-3" to="/checkout/15">
                  $499 MONTHLY
                </Link>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  };

  const renderIndicators = () => {
    if (Array.isArray(slides)) {
      return slides.map((slide, index) => (
        <li
          key={index}
          data-target="#carouselExampleIndicators"
          data-slide-to={index}
          onClick={() => setActive(index)}
          className={active === index ? "active" : ""}
        ></li>
      ));
    }
  };

  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide"
      data-ride="carousel"
    >
      <ol className="carousel-indicators">{renderIndicators()}</ol>
      <div className="carousel-inner">{renderSlides()}</div>
      <div className="carousel-overlay"></div>
    </div>
  );
};

export default AtHomeSlideshow;
