import React, { useState } from "react";

const SignUpForm = ({ handleSubmitSignUp, setLogin, spinner }) => {
  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSubmitSignUp(nombre, correo, password, telefono);
  };

  return (
    <div className="container-fluid ps-0 mt-3 mb-4">
      <h2>Crea tu cuenta</h2>
      <form className="card p-3 no-scale" onSubmit={handleSubmit}>
        <label>Nombre</label>
        <input
          type="text"
          className="form-control mb-3"
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
        />
        <label>Correo</label>
        <input
          type="email"
          className="form-control mb-3"
          value={correo}
          onChange={(e) => setCorreo(e.target.value)}
        />
        <label>Contraseña</label>
        <input
          type="password"
          className="form-control mb-3"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <label>Teléfono</label>
        <input
          type="tel"
          className="form-control mb-3"
          value={telefono}
          onChange={(e) => setTelefono(e.target.value)}
        />
        <div className="row">
          <div className="col-6">
            <button type="submit" className="btn btn-dark">
              {spinner ? (
                <div className="spinner-border"></div>
              ) : (
                "Crear cuenta"
              )}
            </button>
          </div>
          <div className="col-6 text-end">
            <button className="btn btn-link" onClick={() => setLogin(true)}>
              ¿Ya tienes cuenta? Inicia Sesión
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignUpForm;
