import React, { createContext, useContext, useReducer } from "react";
import HomeReducer from "../reducers/HomeReducer";
import PaquetesService from "../services/PaquetesService";
import {
  ESPECIALES_HOME_RECIBIDOS,
  ONLINE_HOME_RECIBIDOS,
  PRESENCIALES_HOME_RECIBIDOS,
} from "../types";
import { ModalContext } from "./ModalContext";

const initialState = {
  online: null,
  presenciales: null,
  coaches: null,
};

export const HomeContext = createContext(initialState);

export const HomeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(HomeReducer, initialState);

  const { success } = useContext(ModalContext);

  const getPaquetesOnline = () => {
    PaquetesService.getOnline().then((res) => {
      const { class_packages } = res.data;
      dispatch({ type: ONLINE_HOME_RECIBIDOS, payload: class_packages });
    });
  };

  const getPresenciales = () => {
    PaquetesService.getPresencialesHome().then((res) => {
      const { paquetes } = res.data;
      dispatch({ type: PRESENCIALES_HOME_RECIBIDOS, payload: paquetes });
    });
  };

  const getEventosEspeciales = () => {
    PaquetesService.getEventosEspecialesHome().then((res) => {
      const { paquetes } = res.data;
      dispatch({ type: ESPECIALES_HOME_RECIBIDOS, payload: paquetes });
    });
  };

  const addToHome = (package_class_id, type) => {
    PaquetesService.addToHome(package_class_id, type).then(() => {
      success("¡Paquete agregado al home con éxito!");
      getPresenciales();
    });
  };

  const deleteFromHome = (package_class_id, type) => {
    PaquetesService.deleteFromHome(package_class_id, type).then(() => {
      success("¡Paquete eliminado del home!");
      getPresenciales();
    });
  };

  return (
    <HomeContext.Provider
      value={{
        ...state,
        getPaquetesOnline,
        getPresenciales,
        getEventosEspeciales,
        deleteFromHome,
        addToHome,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};
