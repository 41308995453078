import { getArgs } from "../utils";
import api from "./api";

const route = "/videos";

export default {
  getVideos: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getAllVideos: (query) =>
    api.get(
      `${route}/admin/all${
        query !== "" && query && query !== null ? `&query=${query}` : ""
      }`
    ),
  getFavoritos: (query) => api.get(`/favorites?${getArgs(query).slice(1)}`),
  getSingleVideo: (video_id) => api.get(`${route}/${video_id}`),
  postFavorito: (video_id) => api.post("/favorites", { video_id }),
  postVideo: (video) => api.post(route, { ...video }),
  putVideo: (video) => api.put(route, { ...video }),
  deleteFavorito: (video_id) => api.delete(`/favorites/${video_id}`),
  deleteVideo: (video_id) => api.delete(`${route}/${video_id}`),
};
