import api from "./api";

const route = "/customer";

export default {
  getCustomer: (customer_id) => api.get(`${route}/admin/${customer_id}`),
  getAllCustomers: (query) =>
    api.get(
      `${route}/admin/all${
        query && query !== null && query !== "" ? `?query=${query}` : ""
      }`
    ),
  getCustomersByQuery: (query) => api.get(`${route}/query?query=${query}`),
  postCustomer: (data) => api.post(route, { ...data }),
  postSignUp: (data) => api.post(`${route}/signup`, { ...data }),
  extenderAcceso: (
    customer_id,
    package_class_id,
    package_days,
    is_gift,
    payment_method_id
  ) =>
    api.post(`${route}/giveAccess`, {
      customer_id,
      package_class_id,
      package_days,
      is_gift,
      payment_method_id,
    }),
  recoverPassword: (email) => api.post(`${route}/recover`, { email }),
};
