import React, { useState } from "react";

const LoginForm = ({ handleSubmitLogin, setLogin, spinner }) => {
  const [correo, setCorreo] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSubmitLogin(correo, password);
  };

  return (
    <div className="container-fluid px-0 mt-3 mb-4">
      <h2>Inicia Sesión</h2>
      <form className="card p-3 no-scale" onSubmit={handleSubmit}>
        <label>Correo</label>
        <input
          type="email"
          className="form-control mb-3"
          value={correo}
          onChange={(e) => setCorreo(e.target.value)}
        />
        <label>Contraseña</label>
        <input
          type="password"
          className="form-control mb-3"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="row">
          <div className="col-6">
            <button type="submit" className="btn btn-dark">
              {spinner ? <div className="spinner-border"></div> : "Entrar"}
            </button>
          </div>
          <div className="col-6 text-end">
            <button className="btn btn-link" onClick={() => setLogin(false)}>
              ¿Aun no tienes cuenta? Regístrate
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
