import React from "react";
import AtHomeNavbar from "../../components/global/AtHomeNavbar";
import Footer from "../../components/global/Footer";

const Terms = () => {
  return (
    <div className="container-fluid">
      <AtHomeNavbar />
      <div className="container">
      <h1>Términos y Condiciones</h1>
      <h2>Latina At Home</h2>
      <h3>1. ¡Bienvenid@!</h3>
      <p>
        Hello Latina y bienvenid@ a Latina At Home. Welcome to the tribe! Estos
        términos y condiciones ("Términos") regulan el uso de nuestra plataforma
        de videos con suscripción mensual recurrente. Al acceder o utilizar
        nuestra página web y servicios, aceptas cumplir con estos Términos. Si
        no estás de acuerdo con alguno de estos Términos, te recomendamos no
        utilizar nuestra plataforma.
      </p>
      <h3>2. Nuestros Servicios</h3>
      <p>
        Te ofrecemos acceso a una increíble biblioteca de más de 250 videos de
        clases del método Latina Tribe en línea, todo mediante una suscripción
        mensual recurrente que puedes cancelar cuando lo desees. Puedes
        encontrar todos los detalles sobre los tipos de videos disponibles,
        incluyendo su duración, temática, coach que imparte la clase y más en la
        misma plataforma.{" "}
      </p>
      <h3>3. Registro y Cuenta</h3>
      <p>
        Para disfrutar de nuestros servicios, primero debes registrarte con tu
        correo, verificarlo y crear una cuenta. Es importante que mantengas la
        confidencialidad de tu cuenta y contraseña. Eres responsable de todas
        las actividades que ocurran bajo tu cuenta. Nos reservamos el derecho de
        suspender o cancelar tu cuenta si sospechamos actividades fraudulentas o
        incumplimiento de estos Términos. (Ley Federal de Protección al
        Consumidor, Artículo 76 Bis).
      </p>
      <h3>4. Pagos y Suscripciones</h3>
      <p>
        Los pagos se realizan de manera recurrente cada mes. Al suscribirte, nos
        autorizas a cargar automáticamente la tarifa de suscripción mensual a tu
        método de pago elegido. Puedes cancelar tu suscripción en cualquier
        momento, pero no otorgamos reembolsos por pagos ya realizados. Mientras
        tu suscripción se mantenga activa, los cargos se realizarán
        automáticamente aún y cuando no accedas o hagas uso de la plataforma.
        Las tarifas de suscripción pueden cambiar, y te notificaremos con
        anticipación cualquier cambio en las tarifas. (Código de Comercio,
        Artículo 46).
      </p>
      <h3>5. Cancelaciones y Reembolsos</h3>
      <p>
        Puedes cancelar tu suscripción en cualquier momento a través de tu
        cuenta en nuestra página web. La cancelación será efectiva al final del
        período de facturación actual. No se otorgarán reembolsos por pagos ya
        realizados. En caso de problemas técnicos que impidan el acceso a los
        videos, por favor, contáctanos por correo a{" "}
        <a href="mailto:contacto@latinatribe.mx">contacto@latinatribe.mx</a>{" "}
        para resolver la situación. (Ley Federal de Protección al Consumidor,
        Artículo 92).
      </p>
      <h3>6. Uso Aceptable</h3>
      <p>
        Nos encanta que utilices nuestra plataforma y te pedimos que lo hagas de
        manera responsable y legal. No debes utilizar nuestros servicios para:
      </p>
      <ul>
        <li>
          Violentar cualquier ley local, estatal, nacional o internacional.
        </li>
        <li>
          Infringir los derechos de propiedad intelectual de Latina Tribe y
          terceros.
        </li>
        <li>Difundir contenido ofensivo, abusivo o difamatorio.</li>
        <li>Lucrar con la reproducción, copia y transmisión del contenido.</li>
      </ul>
      <h3>7. Propiedad Intelectual</h3>
      <p>
        Todo el contenido de nuestra plataforma, incluyendo textos, gráficos,
        imágenes y videos, está protegido por derechos de autor y otras leyes de
        propiedad intelectual. No puedes reproducir, distribuir o modificar
        ningún contenido sin nuestro consentimiento expreso por escrito. (Ley
        Federal del Derecho de Autor, Artículos 27 y 28).
      </p>
      <h3>8. Limitación de Responsabilidad</h3>
      <p>
        En la máxima medida permitida por la ley, no seremos responsables por
        ningún daño indirecto, incidental, especial o consecuente que resulte
        del uso o la incapacidad de uso de nuestros servicios. Nuestra
        responsabilidad total por cualquier reclamación relacionada con nuestros
        servicios se limitará al monto que pagaste por la suscripción en el mes
        en que surgió la reclamación. (Código Civil Federal, Artículo 2110).
      </p>
      <h3>9. Cambios en los Términos</h3>
      <p>
        Nos reservamos el derecho de modificar estos Términos en cualquier
        momento. Te notificaremos cualquier cambio material a través de nuestra
        página web o por correo electrónico. Tu uso continuado de los servicios
        después de cualquier cambio constituye tu aceptación de los nuevos
        Términos. (Ley Federal de Protección al Consumidor, Artículo 85).
      </p>
      <h3>10. Ley Aplicable y Jurisdicción</h3>
      <p>
        Estos Términos se regirán e interpretarán de acuerdo con las leyes de
        México. Cualquier disputa que surja en relación con estos Términos será
        resuelta en los tribunales competentes de Monterrey, Nuevo León, México.
        (Código de Comercio, Artículo 1050).
      </p>
      <h3>11. Contáctanos</h3>
      <p>
        Si tienes alguna pregunta sobre estos Términos, no dudes en contactarnos
        en <a href="mailto:contacto@latinatribe.mx">contacto@latinatribe.mx</a>.
        Estamos aquí para ayudarte. Welcome to the Tribe!{" "}
      </p>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;