import React, { createContext, useContext, useReducer } from "react";
import ClassInstructorReducer from "../reducers/ClassInstructorReducer";
import ClassInstructorService from "../services/ClassInstructorService";
import ClassTypesService from "../services/ClassTypesService";
import {
  CLASS_TYPES_RECIBIDOS,
  CREATE_CLASE,
  RESERVATIONS_RECIBIDAS,
  SEMANAS_RECIBIDAS,
  SET_CLASE,
  SET_PROPIEDAD_CLASE,
  CLASES_RECIBIDAS,
  SET_MONTH,
  SET_WEEK,
  SET_START_DATE,
  SET_END_DATE,
} from "../types";
import { hideModal } from "../utils";
import { ModalContext } from "./ModalContext";
import { AuthContext } from "./AuthContext";
import moment from "moment";

const initialState = {
  weeks: null,
  instructorClass: null,
  reservations: null,
  class_types: null,
  clases: null,
  clase: null,
  month: null,
  selectedWeek: null,
};

export const ClassInstructorContext = createContext(initialState);

export const ClassInstructorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ClassInstructorReducer, initialState);

  const { alert, success } = useContext(ModalContext);

  const { getUsuario } = useContext(AuthContext);

  const setMonth = (month) => {
    dispatch({ type: SET_MONTH, payload: month });
  };

  const setWeek = (week) => {
    dispatch({ type: SET_WEEK, payload: week });
  };

  const getMyReservations = () => {
    ClassInstructorService.getMyReservations().then((res) => {
      const { reservations } = res.data;
      dispatch({ type: RESERVATIONS_RECIBIDAS, payload: reservations });
    });
  };

  const getClases = (fechaInicio, fechaFin) => {
    ClassInstructorService.getClases(fechaInicio, fechaFin).then((res) => {
      const { clases } = res.data;
      dispatch({ type: CLASES_RECIBIDAS, payload: clases });
    });
  };

  const getAsistentes = (class_instructor_id) => {
    ClassInstructorService.getAsistentes(class_instructor_id).then((res) => {
      const { clase } = res.data;
      dispatch({ type: SET_CLASE, payload: clase });
    });
  };

  const getClassTypes = () => {
    ClassTypesService.getClassTypes().then((res) => {
      const { class_types } = res.data;
      dispatch({ type: CLASS_TYPES_RECIBIDOS, payload: class_types });
    });
  };

  const getSchedule = (start_date, end_date) => {
    ClassInstructorService.getWeeks(start_date, end_date).then((res) => {
      const { classes } = res.data;
      dispatch({ type: SEMANAS_RECIBIDAS, payload: classes });
    });
  };

  const postReservacion = (clase) => {
    ClassInstructorService.postReservacion(clase)
      .then(() => {
        hideModal();
        getMyReservations();
        getUsuario();
        success("¡Reservación realizada con éxito!");
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412) {
            return alert(
              "Lo siento bebé, ya no tienes créditos vigentes para clases presenciales."
            );
          } else if (error.response.status === 409) {
            return alert(error.response.data.message);
          }
        }
      });
  };

  const cancelReservacion = (class_reservation_id) => {
    ClassInstructorService.cancelReservacion(class_reservation_id)
      .then(() => {
        hideModal();
        getUsuario();
        getMyReservations();
        success("¡Reservación cancelada con éxito!");
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412) {
            hideModal();
            alert(
              "Lo siento bebé, para cancelar tu clase necesitas al menos 12 horas de anticipación."
            );
          }
        }
      });
  };

  const getClase = (class_instructor_id) => {
    ClassInstructorService.getClase(class_instructor_id).then((res) => {
      const { clase } = res.data;
      dispatch({ type: SET_CLASE, payload: clase });
    });
  };

  const createClase = () => {
    dispatch({ type: CREATE_CLASE });
  };

  const setClase = (clase) => {
    dispatch({ type: SET_CLASE, payload: clase });
  };

  const setPropiedadClase = (key, value) => {
    dispatch({ type: SET_PROPIEDAD_CLASE, payload: { key, value } });
  };

  const postClassType = (description) => {
    ClassTypesService.postClassType(description).then((res) => {
      getClassTypes();
    });
  };

  const postClase = (clase) => {
    clase.class_date = moment(clase.class_date).format("YYYY-MM-DD HH:mm");
    let data = { ...clase };
    delete data.asistentes;
    if (isNaN(data.class_instructor_id)) {
      ClassInstructorService.postClase(data).then(() => {
        success("¡Clase guardada con éxito!");
        getSchedule();
        hideModal();
      });
    } else {
      ClassInstructorService.putClase(data).then(() => {
        success("¡Clase guardada con éxito!");
        getSchedule();
        hideModal();
      });
    }
  };

  const clearClase = () => {
    dispatch({ type: SET_CLASE, payload: null });
  };

  const eliminarClase = (clase) => {
    ClassInstructorService.deleteClase(clase).then(() => {
      success("¡Clase eliminada con éxito!");
      getSchedule();
      hideModal();
    });
  };

  const postAsistenteClase = (
    customer_id,
    class_instructor_id,
    is_cash,
    is_paid
  ) => {
    ClassInstructorService.postAsistenteClase(
      customer_id,
      class_instructor_id,
      is_cash,
      is_paid
    )
      .then(() => {
        getAsistentes(class_instructor_id);
        success("¡Asistente agregado!");
        hideModal();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412) {
            alert("Lo siento bebé, esta clase ya esta llena.");
          }
        }
      });
  };

  const eliminarAsistente = (class_reservation_id, class_instructor_id) => {
    ClassInstructorService.deleteAsistente(class_reservation_id).then(() => {
      getAsistentes(class_instructor_id);
      success("¡Reservacion eliminada!");
      hideModal();
    });
  };

  const postAttend = (class_reservation_id, attend, class_instructor_id) => {
    ClassInstructorService.postAttend(class_reservation_id, attend).then(() => {
      success(attend ? "Asistencia registrada." : "Asistencia cancelada");
      getAsistentes(class_instructor_id);
    });
  };

  const postPayment = (class_reservation_id, is_paid, class_instructor_id) => {
    ClassInstructorService.postPayment(class_reservation_id, is_paid).then(
      () => {
        success(is_paid ? "Pago registrado." : "Pago cancelado.");
        getAsistentes(class_instructor_id);
      }
    );
  };

  const setStartDate = (start_date) => {
    dispatch({ type: SET_START_DATE, payload: start_date });
  };

  const setEndDate = (end_date) => {
    dispatch({ type: SET_END_DATE, payload: end_date });
  };

  return (
    <ClassInstructorContext.Provider
      value={{
        ...state,
        setClase,
        getClase,
        setMonth,
        setWeek,
        getClases,
        postClase,
        setEndDate,
        clearClase,
        postAttend,
        postPayment,
        getSchedule,
        createClase,
        setEndDate,
        setStartDate,
        eliminarClase,
        postClassType,
        getClassTypes,
        getAsistentes,
        eliminarAsistente,
        getMyReservations,
        postAsistenteClase,
        postReservacion,
        cancelReservacion,
        setPropiedadClase,
      }}
    >
      {children}
    </ClassInstructorContext.Provider>
  );
};
