import api from "./api";
import firebase from "firebase";
import "firebase/auth";
import axios from "axios";
import CustomerService from "./CustomerService";
import { randomString } from "../utils";

var firebaseConfig = {
  apiKey: "AIzaSyBv4thDx5_9Dh98Ik8oZXh6rmNkxmqgV3Q",
  authDomain: "latina-dev.firebaseapp.com",
  projectId: "latina-dev",
  storageBucket: "latina-dev.appspot.com",
  messagingSenderId: "269225159374",
  appId: "1:269225159374:web:bdd18aa54b71ebb4bb7e9f",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const getToken = () => auth.currentUser.getIdToken(true);

const auth = firebase.auth();

const oldEndpoint = "https://legacy.latinatribe.mx/api/customer/login";

const handleSignInError = (error, email, password) => {
  if (error.code === "auth/user-not-found") {
    axios
      .post(oldEndpoint, { email, password })
      .then((res) => {
        const { customer_id } = res.data.customer;
        axios
          .get(
            `https://legacy.latinatribe.mx/api/customer?customer_id=${customer_id}`
          )
          .then((response) => {
            let sendReset = false;
            if (password.length < 6) {
              sendReset = true;
              password = randomString(6);
            }
            firebase
              .auth()
              .createUserWithEmailAndPassword(email, password)
              .then((usuario) => {
                const { last_name, second_last_name } = response.data;
                const data = {
                  ...response.data,
                  last_name: `${last_name}${
                    second_last_name !== "" && second_last_name !== null
                      ? ` ${second_last_name}`
                      : ""
                  }`,
                  uid: usuario.user.uid,
                };
                CustomerService.postCustomer(data).then(() => {
                  if (sendReset) {
                    firebase.auth().sendPasswordResetEmail(email);
                    throw `Ya casi estás lista, te hemos enviado un correo a ${email} para que actualices tu contraseña.`;
                  } else {
                    firebase
                      .auth()
                      .signInWithEmailAndPassword(email, password)
                      .then((user) => {
                        return getToken().then((token) => {
                          api.defaults.headers.common["Authorization"] = token;
                          return user;
                        });
                      });
                  }
                });
              })
              .catch((error) => {
                console.log(error);
                throw error;
              });
          })
          .catch((error) => {
            console.log(error);
            throw error;
          });
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }
  console.log(error);
  throw error;
};

const AuthService = {
  signIn: (email, password) =>
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        return getToken().then((token) => {
          api.defaults.headers.common["Authorization"] = token;
          return user;
        });
      })
      .catch((error) => handleSignInError(error, email, password)),
  userLoggedIn: (success, error) =>
    auth.onAuthStateChanged((user) => {
      if (user) {
        getToken().then((token) => {
          api.defaults.headers.common["Authorization"] = token;
          if (success) success(user);
        });
      } else {
        error();
      }
    }),
  signOut: () => auth.signOut(),
  signUp: (correo, password) =>
    auth.createUserWithEmailAndPassword(correo, password),
  recoverPassword: (email) => auth.sendPasswordResetEmail(email),
  getToken: () => auth.currentUser.getIdToken(true),
  updateEmail: (email) => auth.currentUser.updateEmail(email),
};

export default AuthService;
