import api from "./api";

const route = "/class_packages";

export default {
  getPaquetes: () => api.get(`${route}/online/all`),
  getPaquete: (class_package_id) => api.get(`${route}/${class_package_id}`),
  getSingleClassPackage: (class_package_id) =>
    api.get(`${route}/${class_package_id}`),
  getOnline: () => api.get(`${route}/online/all`),
  getAllPaquetes: () => api.get(route),
  getOnlineHome: () => api.get(`${route}/online/home`),
  getPresenciales: () => api.get(`${route}/studio/all`),
  getPresencialesHome: () => api.get(`${route}/presenciales/home`),
  getAsistentesEspecial: (package_class_id) =>
    api.get(`${route}/especiales/${package_class_id}/asistentes`),
  getEventosEspecialesHome: () => api.get(`${route}/especiales/home`),
  getPaquetesEspeciales: () => api.get(`${route}/especiales/all`),
  getPaqueteAdmin: (package_class_id) =>
    api.get(`${route}/admin/${package_class_id}`),
  postPaquete: (paquete) => api.post(route, paquete),
  putPaquete: (paquete) => api.put(route, paquete),
  addToHome: (package_class_id, type) =>
    api.post(`${route}/home/${type}`, { package_class_id }),
  deletePaquete: (package_class_id) =>
    api.delete(`${route}/${package_class_id}`),
  deleteFromHome: (package_class_id, type) =>
    api.delete(`${route}/home/${package_class_id}/${type}`),
};
