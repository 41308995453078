import React from "react";

const Pagination = ({ page, setPage }) => {
  return (
    <div>
      <div
        className="btn btn-light shadow-sm mx-2"
        disabled={page === 1}
        onClick={() => {
          if (page > 1) {
            setPage(page - 1);
          }
        }}
      >
        {"<"}
      </div>
      <span className="mx-2">{page}</span>
      <div
        className="btn btn-light shadow-sm mx-2"
        onClick={() => setPage(page + 1)}
      >
        {">"}
      </div>
    </div>
  );
};

export default Pagination;
